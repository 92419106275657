import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import AwardsGrid from '../components/AwardsGrid'

const AwardsStaticQuery = () => (
  <StaticQuery
    query={graphql`
      query allAwardsContent {
        allBtswebViewtypeJson {
          edges {
            node {
              ID
              Title
              Caption
              Author
              ApprovalStatus
              Status
              Type
              ViewType
              PubDate
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <AwardsGrid
          awards={data.allBtswebViewtypeJson.edges.filter(content => {
            return content.node.ViewType === 'Awards'
          })}
        />
      )
    }}
  />
)

export default AwardsStaticQuery;