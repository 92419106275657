import React, { Component } from 'react'
import { FaTrophy } from 'react-icons/fa'
import styled from 'styled-components'
import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#0c2a4e',
    color: 'white',
    minHeight: '500px',
    minWidth: '400px',
  },
}

const FancyDiv = styled.div`
  margin-bottom: 10px;
  background-color: #0c2a4e;
  padding: 26px;
  font-size: smaller;
  min-height: 240px;
  border-radius: 6px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.45);
`

class AwardsGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      currentAward: [],
    }

    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(id) {
    const currentAward = this.props.awards.map(item => item.node).filter(row => row.ID === id)
    this.setState({
      modalIsOpen: true,
      currentAward,
    })
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = 'rgb(0, 112, 189)'
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  displayAwards = () =>
    this.props.awards
      .map(item => item.node)
      .map((award, i) => {
        let newDate = new Date(award.PubDate)

        let dateFormat = newDate.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })

        return (
          <FancyDiv className='usa-width-one-fourth' key={award.ID + i}>
            <FaTrophy
              height={26}
              width={26}
              size={26}
              style={{
                marginRight: '10px',
                color: 'white',
              }}
            />
            <h3
              style={{
                color: '#0070BD',
                display: 'inline',
                verticalAlign: 'super',
                fontSize: 'medium',
                textTransform: 'capitalize',
              }}
            >
              {award.Title}
            </h3>

            <div
              style={{
                color: '#F7CF30',
                marginTop: '10px',
                fontStyle: 'italic',
              }}
            >
              {dateFormat}
            </div>
            <div style={{ color: 'white', marginTop: '5px' }}>
              <div
                className='award-content'
                dangerouslySetInnerHTML={{
                  __html: award.Caption.substring(0, 100).replace(/<\/?[^>]+(>|$)/g, ''),
                }}
              />
              <button
                onClick={() => {
                  this.openModal(award.ID)
                }}
              >
                Learn More
              </button>
            </div>
          </FancyDiv>
        )
      })

  render() {
    return (
      <React.Fragment>
        {this.state.modalIsOpen && (
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel='Civ Corps Award'
          >
            <div className='usa-grid'>
              <h2 ref={subtitle => (this.subtitle = subtitle)}>{this.state.currentAward[0].Title}</h2>
              <div
                style={{
                  color: '#F7CF30',
                  marginTop: '10px',
                  fontStyle: 'italic',
                }}
              >
                {this.state.currentAward[0].PubDate}
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.currentAward[0].Caption,
                }}
              />
              <button onClick={this.closeModal}>close</button>
            </div>
          </Modal>
        )}

        <div>{this.state.data}</div>
        <div className='usa-grid'>
          <h1 style={{ marginTop: '15px', marginBottom: '48px' }}>Awards and Recognition</h1>
        </div>
        <div className='usa-grid'>{this.displayAwards()}</div>
      </React.Fragment>
    )
  }
}

export default AwardsGrid
