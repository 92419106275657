import React from 'react'
import AwardsStaticQuery from '../components/AwardsStaticQuery'
import Layout from '../components/Layout'

const IndexPage = () => (
  <Layout>
    <AwardsStaticQuery />
  </Layout>
)

export default IndexPage
